import { defineNuxtPlugin } from '#app'

import ElementPlus from 'element-plus'

import * as ElIconModules from '@element-plus/icons-vue'

import { ID_INJECTION_KEY } from 'element-plus'

export default defineNuxtPlugin(nuxtApp =>{
    nuxtApp.vueApp.use(ElementPlus,)
    for(let iconName in ElIconModules){
        nuxtApp.vueApp.component(iconName,ElIconModules[iconName])
    }
    nuxtApp.vueApp.provide(ID_INJECTION_KEY, {
        prefix: Math.floor(Math.random() * 10000),
        current: 0,
      })
})