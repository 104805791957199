<template>
  <div class="wrap">
    <client-only>
    <NuxtLayout>
      <div>
        <NuxtLoadingIndicator />
        <NuxtPage />
      </div>
    </NuxtLayout>
  </client-only>
  </div>
</template>
<script setup>

  import { useFavicon } from '@vueuse/core';

  const {favicon, reset} = useFavicon()

  const nuxtApp = useNuxtApp()

  nuxtApp.hook("page:finish", () => {
     window.scrollTo(0, 0)
  })

  
</script>
<style lang="less" scoped>
    @bgColor:#e4e4e4;
    .wrap{
      background-color: @bgColor;
    }
</style>