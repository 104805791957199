import { meta as _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47attractions_47_91id_93_46vueMeta } from "/workspace/htdocs/www.smwljt.cc/front-end/pages/attractions/[id].vue?macro=true";
import { meta as _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47category_47_91id_93_46vueMeta } from "/workspace/htdocs/www.smwljt.cc/front-end/pages/category/[id].vue?macro=true";
import { meta as _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47category_47strategy_46vueMeta } from "/workspace/htdocs/www.smwljt.cc/front-end/pages/category/strategy.vue?macro=true";
import { meta as _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47contact_47index_46vueMeta } from "/workspace/htdocs/www.smwljt.cc/front-end/pages/contact/index.vue?macro=true";
import { meta as _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47culture_47index_46vueMeta } from "/workspace/htdocs/www.smwljt.cc/front-end/pages/culture/index.vue?macro=true";
import { meta as _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47detail_47_91id_93_46vueMeta } from "/workspace/htdocs/www.smwljt.cc/front-end/pages/detail/[id].vue?macro=true";
import { meta as _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47index_46vueMeta } from "/workspace/htdocs/www.smwljt.cc/front-end/pages/index.vue?macro=true";
import { meta as _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47introduction_47index_46vueMeta } from "/workspace/htdocs/www.smwljt.cc/front-end/pages/introduction/index.vue?macro=true";
import { meta as _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47organizational_47index_46vueMeta } from "/workspace/htdocs/www.smwljt.cc/front-end/pages/organizational/index.vue?macro=true";
import { meta as _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47pictures_47index_46vueMeta } from "/workspace/htdocs/www.smwljt.cc/front-end/pages/pictures/index.vue?macro=true";
import { meta as _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47products_47_91id_93_46vueMeta } from "/workspace/htdocs/www.smwljt.cc/front-end/pages/products/[id].vue?macro=true";
import { meta as _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47search_47index_46vueMeta } from "/workspace/htdocs/www.smwljt.cc/front-end/pages/search/index.vue?macro=true";
export default [
  {
    name: "attractions-id",
    path: "/attractions/:id",
    file: "/workspace/htdocs/www.smwljt.cc/front-end/pages/attractions/[id].vue",
    children: [],
    meta: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47attractions_47_91id_93_46vueMeta,
    alias: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47attractions_47_91id_93_46vueMeta?.alias || [],
    redirect: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47attractions_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/workspace/htdocs/www.smwljt.cc/front-end/pages/attractions/[id].vue").then(m => m.default || m)
  },
  {
    name: "category-id",
    path: "/category/:id",
    file: "/workspace/htdocs/www.smwljt.cc/front-end/pages/category/[id].vue",
    children: [],
    meta: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47category_47_91id_93_46vueMeta,
    alias: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47category_47_91id_93_46vueMeta?.alias || [],
    redirect: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47category_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/workspace/htdocs/www.smwljt.cc/front-end/pages/category/[id].vue").then(m => m.default || m)
  },
  {
    name: "category-strategy",
    path: "/category/strategy",
    file: "/workspace/htdocs/www.smwljt.cc/front-end/pages/category/strategy.vue",
    children: [],
    meta: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47category_47strategy_46vueMeta,
    alias: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47category_47strategy_46vueMeta?.alias || [],
    redirect: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47category_47strategy_46vueMeta?.redirect || undefined,
    component: () => import("/workspace/htdocs/www.smwljt.cc/front-end/pages/category/strategy.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    file: "/workspace/htdocs/www.smwljt.cc/front-end/pages/contact/index.vue",
    children: [],
    meta: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47contact_47index_46vueMeta,
    alias: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47contact_47index_46vueMeta?.alias || [],
    redirect: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47contact_47index_46vueMeta?.redirect || undefined,
    component: () => import("/workspace/htdocs/www.smwljt.cc/front-end/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "culture",
    path: "/culture",
    file: "/workspace/htdocs/www.smwljt.cc/front-end/pages/culture/index.vue",
    children: [],
    meta: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47culture_47index_46vueMeta,
    alias: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47culture_47index_46vueMeta?.alias || [],
    redirect: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47culture_47index_46vueMeta?.redirect || undefined,
    component: () => import("/workspace/htdocs/www.smwljt.cc/front-end/pages/culture/index.vue").then(m => m.default || m)
  },
  {
    name: "detail-id",
    path: "/detail/:id",
    file: "/workspace/htdocs/www.smwljt.cc/front-end/pages/detail/[id].vue",
    children: [],
    meta: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47detail_47_91id_93_46vueMeta,
    alias: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47detail_47_91id_93_46vueMeta?.alias || [],
    redirect: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47detail_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/workspace/htdocs/www.smwljt.cc/front-end/pages/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    file: "/workspace/htdocs/www.smwljt.cc/front-end/pages/index.vue",
    children: [],
    meta: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47index_46vueMeta,
    alias: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47index_46vueMeta?.alias || [],
    redirect: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/workspace/htdocs/www.smwljt.cc/front-end/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "introduction",
    path: "/introduction",
    file: "/workspace/htdocs/www.smwljt.cc/front-end/pages/introduction/index.vue",
    children: [],
    meta: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47introduction_47index_46vueMeta,
    alias: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47introduction_47index_46vueMeta?.alias || [],
    redirect: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47introduction_47index_46vueMeta?.redirect || undefined,
    component: () => import("/workspace/htdocs/www.smwljt.cc/front-end/pages/introduction/index.vue").then(m => m.default || m)
  },
  {
    name: "organizational",
    path: "/organizational",
    file: "/workspace/htdocs/www.smwljt.cc/front-end/pages/organizational/index.vue",
    children: [],
    meta: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47organizational_47index_46vueMeta,
    alias: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47organizational_47index_46vueMeta?.alias || [],
    redirect: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47organizational_47index_46vueMeta?.redirect || undefined,
    component: () => import("/workspace/htdocs/www.smwljt.cc/front-end/pages/organizational/index.vue").then(m => m.default || m)
  },
  {
    name: "pictures",
    path: "/pictures",
    file: "/workspace/htdocs/www.smwljt.cc/front-end/pages/pictures/index.vue",
    children: [],
    meta: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47pictures_47index_46vueMeta,
    alias: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47pictures_47index_46vueMeta?.alias || [],
    redirect: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47pictures_47index_46vueMeta?.redirect || undefined,
    component: () => import("/workspace/htdocs/www.smwljt.cc/front-end/pages/pictures/index.vue").then(m => m.default || m)
  },
  {
    name: "products-id",
    path: "/products/:id",
    file: "/workspace/htdocs/www.smwljt.cc/front-end/pages/products/[id].vue",
    children: [],
    meta: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47products_47_91id_93_46vueMeta,
    alias: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47products_47_91id_93_46vueMeta?.alias || [],
    redirect: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47products_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/workspace/htdocs/www.smwljt.cc/front-end/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    file: "/workspace/htdocs/www.smwljt.cc/front-end/pages/search/index.vue",
    children: [],
    meta: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47search_47index_46vueMeta,
    alias: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47search_47index_46vueMeta?.alias || [],
    redirect: _47workspace_47htdocs_47www_46smwljt_46cc_47front_45end_47pages_47search_47index_46vueMeta?.redirect || undefined,
    component: () => import("/workspace/htdocs/www.smwljt.cc/front-end/pages/search/index.vue").then(m => m.default || m)
  }
]