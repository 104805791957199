import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(() => {
  if (process.client) {
    // 屏幕适应
    (function (win, doc) {
      if (!win.addEventListener)
        return
      function setFont() {
        let screenWidth = document.querySelector('html')!.offsetWidth
        const baseSz = 100
        const pageWidth = 750
        if (screenWidth > 640)
          screenWidth = 640

        let fZ = (baseSz * screenWidth) / pageWidth
        if (fZ > 85)
          fZ = 85
        document.querySelector('html')!.style.fontSize = `${fZ}px`
      }
      setFont()
      setTimeout(() => {
        setFont()
      }, 300)
      doc.addEventListener('DOMContentLoaded', setFont, false)
      win.addEventListener('resize', setFont, false)
      win.addEventListener('load', setFont, false)
    })(window, document)
  }
})