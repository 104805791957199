export const appHead = {"meta":[{"charset":"utf-8"},{"name":"description","content":"神木文旅集团秉承“文以化人，旅以载道”的宗旨，以“智慧创业，奋斗幸福”作为企业的核心价值观，发扬“善良、微笑、爱”的服务理念。"},{"name":"keywords","content":"神木|文化|旅游|文旅集团|神木文旅集团"},{"name":"viewport","content":"width=device-width,initial-scale=1.0"}],"link":[{"rel":"shortcut icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[],"noscript":[],"title":"神木文旅集团"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appAssetsPath = {}

export const appCdnURL = ""

export const appLayoutTransition = {"name":"layout","mode":"out-in"}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appKeepalive = false