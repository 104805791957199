import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/workspace/htdocs/www.smwljt.cc/front-end/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/workspace/htdocs/www.smwljt.cc/front-end/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_mixin_plugin_mjs_prWV5EzJWI from "/workspace/htdocs/www.smwljt.cc/front-end/node_modules/nuxt/dist/head/runtime/mixin-plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/workspace/htdocs/www.smwljt.cc/front-end/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc from "/workspace/htdocs/www.smwljt.cc/front-end/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import plugins_ElementPlus_ts_kXLj4mSW4A from "/workspace/htdocs/www.smwljt.cc/front-end/plugins/ElementPlus.ts";
import plugins_VueSwiper_ts_kS3jqcyHUP from "/workspace/htdocs/www.smwljt.cc/front-end/plugins/VueSwiper.ts";
import plugins_EventBus_ts_SgFHgBWmad from "/workspace/htdocs/www.smwljt.cc/front-end/plugins/EventBus.ts";
import plugins_PostcssPxtorem_ts_gOorgTV8xS from "/workspace/htdocs/www.smwljt.cc/front-end/plugins/PostcssPxtorem.ts";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_head_runtime_mixin_plugin_mjs_prWV5EzJWI,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc,
  plugins_ElementPlus_ts_kXLj4mSW4A,
  plugins_VueSwiper_ts_kS3jqcyHUP,
  plugins_EventBus_ts_SgFHgBWmad,
  plugins_PostcssPxtorem_ts_gOorgTV8xS
]